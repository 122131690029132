import PropTypes, { func } from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Comment, List, Tooltip, Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { convertToHTML } from 'draft-convert'
import AuthService from '../../../services/AuthService'
import Loader from '../../Loader'
import './Notes.css'
import RichEditor from '../../RichEditor'
import { removeTags } from '../../../services/utils'

const updateNotesWithNames = (notes, collaborators, t) =>
  notes.map((note) => {
    const index = collaborators.map((e) => e.id).indexOf(note.user_id)
    if (index >= 0) {
      note.user = collaborators[index].display
    } else {
      note.user = t('candidate:user.removed.message')
    }
    return note
  })

const Auth = new AuthService()

const propTypes = {
  candidateId: PropTypes.string.isRequired,
  conversationId: PropTypes.string,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      timestamp: PropTypes.string,
      user_id: PropTypes.string,
      text: PropTypes.string,
      conversation_id: PropTypes.string,
    }),
  ).isRequired,
  refetchCandidate: func,
  whitelistedGuests: PropTypes.array,
}

const defaultProps = {
  conversationId: '',
  refetchCandidate: null,
  whitelistedGuests: [],
}

const Notes = ({
  refetchCandidate,
  notes: prNotes,
  candidateId,
  conversationId,
  whitelistedGuests,
}) => {
  const { t } = useTranslation(['common', 'candidate'], { useSuspense: false })
  const abortController = new AbortController()

  const user = useSelector((state) => state.user)

  const [notes, setNotes] = useState(prNotes)
  const [status, setStatus] = useState('fetching')
  const [editNoteState, setEditNoteState] = useState(EditorState.createEmpty())
  const [noteState, setNoteState] = useState(EditorState.createEmpty())
  const [collaborators, setCollaborators] = useState([])
  const [isDeleteNoteOpen, setIsDeleteNoteOpen] = useState(false)
  const [isEditNoteOpen, setIsEditNoteOpen] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)

  useEffect(() => {
    const fetchCollaborators = async () => {
      try {
        const users = await Auth.fetch('/users?$limit=-1', {
          signal: abortController.signal,
        })
        const collaborators = users.map((user) => {
          let name = user.login
          if (user?.firstname && user?.lastname) {
            name = `${user.firstname} ${user.lastname}`
          }
          return {
            id: user._id,
            display: name,
            profile: user.permissions[0],
          }
        })

        // here we generate a fake user for auto suggest to allow to ping all collaborators.

        collaborators.unshift({
          id: undefined,
          display: 'all',
          profile: 'user',
        })

        const updatedNotes = updateNotesWithNames(
          JSON.parse(JSON.stringify(notes)),
          collaborators,
          t,
        )

        setNotes(updatedNotes)
        setCollaborators(collaborators)
        setStatus('fetched')
      } catch (e) {
        console.error('Error while fetching users', e)
      }
    }

    fetchCollaborators()
  }, [candidateId])

  useEffect(() => () => abortController.abort(), [])

  const handleDeleteNote = async () => {
    try {
      await Auth.fetch(`/candidates/${candidateId}`, {
        method: 'PATCH',
        body: JSON.stringify({
          $pull: {
            notes: {
              _id: selectedNote.id,
            },
          },
        }),
      })

      const updatedNotes = notes.filter((note) => note._id !== selectedNote.id)

      setIsDeleteNoteOpen(false)
      setSelectedNote(null)
      setNotes(updatedNotes)

      if (refetchCandidate) {
        refetchCandidate()
      }
      toast.success(
        t('candidate:note.removed.toast.success', 'Note supprimée !'),
      )
    } catch (e) {
      console.error(e)
      toast.error(
        t(
          'candidate:note.removed.toast.error',
          'Erreur lors de la suppression de la notes',
        ),
      )
    }
  }

  const handleClickEditNote = async (note) => {
    setIsEditNoteOpen(true)

    const noteIndex = notes.map((e) => e._id).indexOf(note.id)
    const noteToEdit = notes[noteIndex]

    setSelectedNote(noteToEdit)

    const { rawContent, text } = noteToEdit

    const editorState = EditorState.createWithContent(
      rawContent
        ? convertFromRaw(JSON.parse(rawContent))
        : stateFromHTML(text || ''),
    )

    setEditNoteState(editorState)
  }

  const handleEditNote = async () => {
    try {
      const text = removeTags(convertToHTML(editNoteState.getCurrentContent()))
      const mentions = []

      // here we check if every user had been mentionned using everyone before checking for each user to improve performance

      if (text.includes(`@all`)) {
        // i is equal to 1 because first user is the fake user @all
        for (let i = 1; i < collaborators.length; i++) {
          if (collaborators[i].id !== user._id) {
            mentions.push(collaborators[i].id)

            if (collaborators[i].id.profile === 'guest') {
              if (
                whitelistedGuests &&
                whitelistedGuests
                  .map((e) => e.toString())
                  .indexOf(collaborators[i].id) < 0
              ) {
                whitelistedGuests.push(collaborators[i].id)
              }
            }
          }
        }
      } else {
        // this is where we add each user if everyone isn't used
        collaborators.forEach((user) => {
          if (text.includes(`@${user.display}`)) {
            mentions.push(user.id)

            if (user.profile === 'guest') {
              if (
                whitelistedGuests &&
                whitelistedGuests.map((e) => e.toString()).indexOf(user.id) < 0
              ) {
                whitelistedGuests.push(user.id)
              }
            }
          }
        })
      }

      const updatedNotes = JSON.parse(JSON.stringify(notes))
      const editedNoteIndex = notes.map((e) => e._id).indexOf(selectedNote._id)

      updatedNotes[editedNoteIndex] = {
        ...updatedNotes[editedNoteIndex],
        text,
        rawContent: JSON.stringify(
          convertToRaw(editNoteState.getCurrentContent()),
        ),
      }

      const promises = [
        Auth.fetch(`/candidates/${candidateId}`, {
          method: 'PATCH',
          body: JSON.stringify({
            notes: updatedNotes,
            mentions,
            conversationId,
          }),
        }),
      ]

      const [candidate] = await Promise.all(promises)

      const updateddNotes = updateNotesWithNames(
        candidate.notes,
        collaborators,
        t,
      )

      setIsEditNoteOpen(false)
      setNotes(updateddNotes)
      setEditNoteState(EditorState.createEmpty())

      if (refetchCandidate) {
        refetchCandidate()
      }
    } catch (e) {
      console.error('Error while updating notes', e)
      toast.error(
        t(
          'candidate:note.updated.toast.error',
          'Erreur lors de la mise à jour de la note',
        ),
      )
    }
  }

  const handleSubmitNotes = async () => {
    try {
      const text = convertToHTML(noteState.getCurrentContent())
      const mentions = []
      // here we check if every user had been mentionned using everyone before checking for each user to improve performance

      if (text.includes(`@all`)) {
        // i is equal to 1 because first user is the fake user @all
        for (let i = 1; i < collaborators.length; i++) {
          if (collaborators[i].id !== user._id) {
            mentions.push(collaborators[i].id)

            if (collaborators[i].id.profile === 'guest') {
              if (
                whitelistedGuests &&
                whitelistedGuests
                  .map((e) => e.toString())
                  .indexOf(collaborators[i].id) < 0
              ) {
                whitelistedGuests.push(collaborators[i].id)
              }
            }
          }
        }
      } else {
        // this is where we add each user if everyone isn't used
        collaborators.forEach((user) => {
          if (text.includes(`@${user.display}`)) {
            mentions.push(user.id)

            if (user.profile === 'guest') {
              if (
                whitelistedGuests &&
                whitelistedGuests.map((e) => e.toString()).indexOf(user.id) < 0
              ) {
                whitelistedGuests.push(user.id)
              }
            }
          }
        })
      }

      const candidate = await Auth.fetch(`/candidates/${candidateId}`, {
        method: 'PATCH',
        body: JSON.stringify({
          $push: {
            notes: {
              text,
              user_id: user._id,
              ...(conversationId && {
                conversation_id: conversationId,
              }),
              mentionedUsers_id: mentions,
              rawContent: JSON.stringify(
                convertToRaw(noteState.getCurrentContent()),
              ),
            },
          },
          mentions,
        }),
      })

      if (conversationId && whitelistedGuests) {
        await Auth.fetch(`/conversations/${conversationId}`, {
          method: 'PATCH',
          body: JSON.stringify({
            whitelistedGuests,
          }),
        })
      }

      const updatedNotes = updateNotesWithNames(
        candidate.notes,
        collaborators,
        t,
      )

      setNotes(updatedNotes)
      setNoteState(EditorState.createEmpty())
      toast.success(t('candidate:note.added.toast.success'))
    } catch (e) {
      console.error('Error while submitting notes', e)
      toast.error(
        t(
          'candidate:note.added.toast.error',
          "Erreur lors de l'ajout de la note à la candidature",
        ),
      )
    }
  }

  let displayedNotes = [...notes]

  if (user.permissions?.includes('guest')) {
    displayedNotes = displayedNotes.filter(
      (note) =>
        !!(
          note.user_id === user._id ||
          note.mentionedUsers_id?.includes(user._id)
        ),
    )
  }

  const data = displayedNotes.map((note) => ({
    id: note._id,
    userId: note.user_id,
    author: note.user,
    content: (
      <div
        className="comment"
        dangerouslySetInnerHTML={{
          __html: note.rawContent
            ? convertToHTML(convertFromRaw(JSON.parse(note.rawContent)))
            : note.text,
        }}
      />
    ),
    datetime: <span>{moment(new Date(note.timestamp)).fromNow()}</span>,
  }))

  if (status === 'fetching') {
    return <Loader />
  }

  const content = (
    <>
      <div
        style={
          {
            // height: '25px',
          }
        }
      >
        <div className="pull-right">
          <Tooltip
            title={t(
              'candidate:mentions.tooltip.title',
              'Vous pouvez taguer un collaborateur en utilisant le caractère @. Les profils invités ne peuvent voir que les notes dans lesquelles ils ont été tagués.',
            )}
            placement="left"
            content={
              <FontAwesomeIcon
                style={{
                  fontSize: '20px',
                  marginRight: '5px',
                  marginTop: '5px',
                  cursor: 'pointer',
                }}
                className="opacity-8"
                icon={faQuestionCircle}
              />
            }
          />
        </div>
      </div>
      <div className="input-wrapper">
        <RichEditor
          editorState={noteState}
          setEditorState={setNoteState}
          specialSuggestions={collaborators
            .filter((e) => e.id !== user._id)
            .map((collaborator) => ({
              text: collaborator.display,
              value: collaborator.display,
            }))}
        />
        <Button
          className="basic-btn"
          disabled={
            !removeTags(convertToHTML(noteState.getCurrentContent())).trim()
          }
          onClick={handleSubmitNotes}
        >
          {t('common:add')}
        </Button>
      </div>

      <div>
        {notes.length > 0 ? (
          <List
            className="comment-list"
            header={`${data.length} notes`}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <li>
                <Comment
                  style={{ whiteSpace: 'pre' }}
                  actions={
                    user._id === item.userId
                      ? [
                          <div
                            role="button"
                            onClick={() => {
                              handleClickEditNote(item)
                            }}
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                          >
                            <p>{t('common:edit')}</p>
                          </div>,
                          <div
                            role="button"
                            onClick={() => {
                              setIsDeleteNoteOpen(true)
                              setSelectedNote(item)
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <p>{t('common:delete')}</p>
                          </div>,
                        ]
                      : []
                  }
                  author={item.author}
                  content={item.content}
                  datetime={item.datetime}
                />
              </li>
            )}
          />
        ) : (
          ''
        )}
      </div>
    </>
  )

  return (
    <div className="notes-wrapper">
      {content}
      <Modal
        visible={isDeleteNoteOpen}
        onCancel={() => {
          setIsDeleteNoteOpen(false)
          setSelectedNote(null)
        }}
        footer={
          <div className="btns-container">
            <button
              type="button"
              className="grey-btn"
              onClick={() => setIsDeleteNoteOpen(false)}
            >
              {t('common:cancel')}
            </button>
            <button
              type="button"
              className="basic-btn"
              onClick={handleDeleteNote}
            >
              {t('common:delete')}
            </button>
          </div>
        }
      >
        {t('candidate:remove.note.message', 'Voulez vous supprimer la note ?')}
      </Modal>
      <Modal
        visible={isEditNoteOpen}
        onCancel={() => {
          setIsEditNoteOpen(false)
          setSelectedNote(null)
        }}
        footer={
          <div className="btns-container">
            <button
              type="button"
              className="grey-btn"
              onClick={() => {
                setIsEditNoteOpen(false)
              }}
            >
              {t('common:cancel')}
            </button>
            <button
              type="button"
              className="basic-btn"
              onClick={handleEditNote}
              disabled={
                !removeTags(
                  convertToHTML(editNoteState.getCurrentContent()),
                ).trim()
              }
            >
              {t('common:save')}
            </button>
          </div>
        }
      >
        <div className="input-wrapper">
          <RichEditor
            editorState={editNoteState}
            setEditorState={setEditNoteState}
            specialSuggestions={collaborators
              .filter((e) => e.id !== user._id)
              .map((collaborator) => ({
                text: collaborator.display,
                value: collaborator.display,
              }))}
          />
        </div>
      </Modal>
    </div>
  )
}

Notes.propTypes = propTypes
Notes.defaultProps = defaultProps

export default Notes
